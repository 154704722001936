/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration'
import { ensureCurrentUser } from '../../../util/data'
import { FormattedMessage } from '../../../util/reactIntl'
import { propTypes } from '../../../util/types'

import { AvatarLarge, InlineTextButton, NamedLink } from '../../../components'

import { apiBaseUrl } from '../../../util/api'
import css from './TopbarMobileMenu.module.css'

const TopbarMobileMenu = (props) => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUser,
    notificationCount,
    onLogout,
    config
  } = props

  const user = ensureCurrentUser(currentUser)

  const isProvider = user?.attributes.profile.privateData?.userType === 'provider' || false

  const currentPageClass = (page) => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage)
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null
  }

  if (!isAuthenticated) {
    return (
      <div className={css.root}>
        <div className={css.content}>
          <div className={css.authenticationGreeting}>Welcome!</div>
          <a
            href="https://seestella.com.au"
            target="_blank"
            className={classNames(css.navigationLink, currentPageClass('ManageListingsPage'))}
            rel="noreferrer"
          >
            Learn about Stella
          </a>
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('SearchPage'))}
            name="SearchPage"
          >
            Find a Practitioner
          </NamedLink>
          <a
            href="https://www.seestella.com.au/#howItWorks"
            target="_blank"
            className={classNames(css.navigationLink, currentPageClass('ManageListingsPage'))}
            rel="noreferrer"
          >
            How it works
          </a>
          <a
            href="https://seestella.com.au/practitioners"
            target="_blank"
            className={classNames(css.navigationLink, currentPageClass('ManageListingsPage'))}
            rel="noreferrer"
          >
            For practitioners
          </a>
          <a
            href="https://www.seestella.com.au/faqs"
            target="_blank"
            className={classNames(css.navigationLink, currentPageClass('ManageListingsPage'))}
            rel="noreferrer"
          >
            FAQs
          </a>
        </div>
        <div className={css.footer}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            Australia's online directory for medical aesthetics
          </div>
          <div style={{ display: 'flex', marginBottom: '16px', justifyContent: 'center' }}>
            Sign up to get started
          </div>
          <div
            style={{ cursor: 'pointer', marginBottom: '10px' }}
            onClick={() => {
              if (window) {
                window.location.href = `${apiBaseUrl()}/api/auth/logto?first_screen=register`
              }
            }}
            className={css.createNewListingLink}
          >
            Sign up
          </div>
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (window) {
                window.location.href = `${apiBaseUrl()}/api/auth/logto?first_screen=signIn`
              }
            }}
            className={css.loginLink}
          >
            Log in
          </div>
        </div>
      </div>
    )
  }

  const displayName = user.attributes.profile.firstName

  return (
    <div className={css.root}>
      <AvatarLarge className={css.avatar} user={currentUser} />
      <div className={css.content}>
        <span className={css.greeting}>
          <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
        </span>
        <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton>
        {!isProvider && <div className={css.menuDivider} />}

        {isProvider && (
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <FormattedMessage id="TopbarMobileMenu.yourListingsLink" />
          </NamedLink>
        )}
        {isProvider && (
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('ConsultRequestsPage'))}
            name="ConsultRequestsPage"
          >
            <FormattedMessage id="TopbarMobileMenu.consultRequestLink" />
          </NamedLink>
        )}
        {isProvider && (
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('SearchPage'))}
            name="SearchPage"
          >
            Search Practitioners
          </NamedLink>
        )}
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ProfileSettingsPage'))}
          name="ProfileSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))}
          name="AccountSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
        </NamedLink>

        {!isProvider ? (
          <>
            <NamedLink
              className={classNames(css.navigationLink, currentPageClass('SearchPage'))}
              name="SearchPage"
            >
              Find a Practitioner
            </NamedLink>

            <div className={css.menuDivider} />

            <a
              href="https://seestella.com.au"
              target="_blank"
              className={classNames(css.navigationLink)}
              rel="noreferrer"
            >
              Learn about Stella
            </a>
            <a
              href="https://www.seestella.com.au/#howItWorks"
              target="_blank"
              className={classNames(css.navigationLink)}
              rel="noreferrer"
            >
              How it works
            </a>
            <a
              href="https://www.seestella.com.au/faqs"
              target="_blank"
              className={classNames(css.navigationLink)}
              rel="noreferrer"
            >
              FAQs
            </a>
          </>
        ) : (
          <>
            <div className={css.menuDivider} />
            <a
              href="https://seestella.com.au"
              target="_blank"
              className={classNames(css.navigationLink)}
              rel="noreferrer"
            >
              Learn about Stella
            </a>
            <a
              href="https://www.seestella.com.au/#howItWorks"
              target="_blank"
              className={classNames(css.navigationLink)}
              rel="noreferrer"
            >
              How it works
            </a>
            <a
              href="https://seestella.com.au/practitioners"
              target="_blank"
              className={classNames(css.navigationLink)}
              rel="noreferrer"
            >
              For practitioners
            </a>
            <a
              href="https://www.seestella.com.au/faqs"
              target="_blank"
              className={classNames(css.navigationLink)}
              rel="noreferrer"
            >
              FAQs
            </a>
          </>
        )}
        <div className={css.spacer} />
      </div>

      {/* {isProvider && (
        <div className={css.footer}>
          <NamedLink className={css.createNewListingLink} name="NewListingPage">
            <FormattedMessage id="TopbarMobileMenu.newListingLink" />
          </NamedLink>
        </div>
      )} */}
    </div>
  )
}

TopbarMobileMenu.defaultProps = {
  currentUser: null,
  notificationCount: 0,
  currentPage: null
}

const { bool, func, number, string } = PropTypes

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired
}

export default TopbarMobileMenu
